html,
body {
  margin: 0;
}
// CSS for DataGrid ONCell Click Started
.cursor-pointer {
  cursor: pointer;
}

.react-datepicker__header {
  background-color: #f5f5f5 !important; /* Light background for header */
  border-bottom: 1px solid #ddd !important; /* Border at the bottom of the header */
  padding: 15px !important;
  border-radius: 8px 8px 0 0 !important; /* Rounded top corners */
}

.react-datepicker__current-month {
  font-size: 16px !important; /* Adjust font size for the month name */
  color: #333 !important; /* Dark color for text */
}

.react-datepicker__day {
  font-size: 14px !important; /* Adjust font size for days */
  color: #333 !important; /* Dark color for day numbers */
}

.react-datepicker__day--selected {
  background-color: #007bff !important; /* Background color for selected day */
  color: white !important; /* Text color for selected day */
}

.react-datepicker__day--hovered {
  background-color: #e9ecef !important; /* Background color on hover */
}

.react-datepicker{
  border:1px solid #dadada !important;
}

.swiper-pagination{
  position: static !important;
  margin-top:20px
}
.swiper-pagination-bullet {
  background-color: #FFCC00 !important;
  opacity: 1  !important;
  width: 16px  !important;
  height: 16px  !important;
  margin: 0 5px  !important;
  border-radius: 50%  !important;
}
.swiper-pagination-bullet-active {
  background-color: black !important;
}

.rdrCalendarWrapper{
  background-color: #E3EDED !important;
  border: 1px solid #CFD2D6 ;
  border-radius: 8px ;
}
.rdrNextPrevButton , .rdrNextButton {
  background-color: #E3EDED !important ;
  border: 1px solid #CFD2D6 !important;
  width:44px !important;
  height: 44px !important;
}
.rdrNextButton i{
  margin: auto !important;
}
.rdrMonth{
  background-color: #FFFFFF33 !important;
  margin: 8px !important;
  border-radius: 8px ;
}

.css-7bc43y-MuiList-root-MuiMultiSectionDigitalClockSection-root::after {
height: auto !important;
}
.rdrDay,.rdrDayToday{
  padding: 20px !important;
  height: 42px !important;
  font-weight: 400 !important;
  color: #0D1E32 !important;
}
.rdrSelected {
  border-radius: 30px !important;
  background-color: #FFCC00  !important;
}
.rdrDayEndPreview,.rdrDayStartPreview {
  border-radius: 500px !important;
  border: none !important;
}
.rdrDayNumber{
  border-radius: 5px !important;
}
.rdrDayDisabled{
background-color: #FFFFFF00 !important;
}
.rdrDayHovered .rdrDayNumber::after{
  border: none !important;
}
.rdrDayPassive{
  pointer-events: visible !important;
}
.propertyImage{
  width: 150px;
  height:100%;
  border-radius: 12px;
}

@media (max-width: 600px) {
  .propertyImage {
    width: 100% !important;
    height: 150px !important;

  }
}

