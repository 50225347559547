@font-face {
  font-family: 'TheHistoriaDemo';
  src: url('/public/fonts/TheHistoriaDemo/FontsFree-Net-TheHistoriaDemo.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron-Light';
  src: url('/public/fonts/AlietronFonts/Aileron-Light.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Aileron-Regular';
  src: url('/public/fonts/AlietronFonts/Aileron-Regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Aileron-SemiBold';
  src: url('/public/fonts/AlietronFonts/Aileron-SemiBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html,
body {
  margin: 0;
  font-family: "Aileron-Regular" ;
}




